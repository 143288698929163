import React, { useRef } from 'react'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { COLORS } from 'COLORS'
import { TEXT_STYLES } from 'TEXT_STYLES'
import { flagDataTypes } from 'types/general'
import { NAVBAR_STYLE } from 'constants/styles'

import { media } from 'helpers/media_queries'
import { showSidebarItem } from 'helpers/sidebar'
import { TRANSITION_TIMING } from 'constants/sidebar'

import { CollapseIconV2 as CollapseIcon } from 'icons/CollapseIcon/CollapseIconV2'

const checkForMediaQuery = (props, style) => {
  const { ignoreToggleState, collapsed } = props

  if (ignoreToggleState) return media.desktop(style)
  if (!collapsed) return style

  return null
}

const ItemWrapper = styled.div`
  width: 100%;
  position: relative;
  height: 44px;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  flex-shrink: 0;

  ${props =>
    props.isExpanded &&
    `
      height: ${props.expandedHeight}px;
    `}

  svg:first-of-type {
    min-width: 20px;
  }

  svg:nth-of-type(2) {
    display: ${props => (props.isExpanded ? `block` : `none`)};
    margin-left: auto;
    margin-right: 12px;
  }

  &:hover {
    ${props => !props.isActive && `background-color: ${COLORS.grey950};`}

    svg:nth-of-type(2) {
      display: block;
    }
  }

  &::after {
    content: '';
    width: 3px;
    height: 100%;
    top: 0px;
    left: -1px;
    border-right: 1px solid transparent;
    border-radius: 2px;
    position: absolute;
  }
  ${props =>
    props.isActive &&
    `
    background-color: black;
    &::after {
      background-color: ${props.theme.colors.primarySidenav};
    }
  `}

  ${props =>
    props.isExpanded &&
    !props.isActive &&
    `background-color: ${COLORS.grey950};`}
`

const activeStatus = props => `
  color: ${props.theme.colors.white};
  svg:first-of-type {
    color: ${props.theme.colors.primarySidenav};
  }
`

const linkStyles = props => `
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  transition: all 0.2s ease;
  color: ${props.theme.colors.grey400};
  font-size: 14px;
  font-weight: 500;
  padding-left: 16px;
  cursor: pointer;
  ${props.active &&
    `
    color: ${props.theme.colors.primarySidenav};
    ${activeStatus(props)}
  `}
  ${!props.active && props.isExpanded && `background-color: ${COLORS.grey950};`}
  &:hover,
  &.active {
    ${activeStatus(props)};
    background-color: ${COLORS.grey950};
  }
  ${checkForMediaQuery(
    props.item,
    `
    padding-left: 16px;
    `
  )};
  ${props.item && props.item.isActive && activeStatus(props)};
`

const CustomNav = styled.div`
  ${linkStyles}
  width: ${props => (props.collapsed ? '52px' : 'auto')};
  transition: width 0.3s ${TRANSITION_TIMING};
`

const CustomAnchor = styled.a`
  ${linkStyles}
`

const CustomNavLink = styled(NavLink)`
  ${linkStyles}
`

const CustomSubLink = styled(NavLink)`
  display: flex;
  width: 100%;
  align-items: center;
  transition: color 0.2s ease;
  color: ${props => props.theme.colors.grey300};
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 12.5px;
  padding-left: 36px;
  transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: ${props => (props.isExpanded ? 1 : 0)};
  position: relative;
  ${props =>
    props.active &&
    `
    color: ${props.theme.colors.primarySidenav};
    ${activeStatus}
    circle {
      stroke: ${props.theme.colors.grey900};
    }
  `}
  &:hover,
  &.active {
    ${activeStatus}
    circle {
      stroke: ${props => props.theme.colors.grey900};
    }
  }
  &.active {
    color: ${props => props.theme.colors.primarySidenav};
  }
`

const Separator = styled.hr`
  position: relative;
  left: 0;
  width: ${props =>
    props.collapsed ? '36px' : `calc(${NAVBAR_STYLE.width} - 16px)`};
  border-top: 1px solid ${COLORS.grey100};
  border-bottom: none;
  border-radius: 0.5px;
  margin-top: 0;
  margin-bottom: 0;
  transition: width 0.3s ${TRANSITION_TIMING};
`

export const ItemText = styled.div`
  overflow: hidden;
  user-select: none;
  opacity: ${props => (props.collapsed ? '0' : '1')};
  padding-right: 10px;
  white-space: ${props => (props.enableWrapping ? 'pre' : 'nowrap')};
  margin-left: 12px;
  transition: opacity 0.3s ${TRANSITION_TIMING};
`

const ChildrenContainer = styled.div`
  padding-top: 5px;
  ${props => props.isActive && 'padding-left: 60px;'}
  [class*=CustomSubLink] {
    opacity: ${props => (props.isExpanded ? 1 : 0)};
  }
`

export const NewBadge = styled.span`
  display: inline-block;
  ${TEXT_STYLES.H5Black};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  margin-left: 8px;
  padding: 2px 4px;
`

export const MobileMenuItem = props => {
  const handleParentClick = name => {
    const { expandSidebarOption } = props
    expandSidebarOption(name)
  }

  const handleChildrenClick = () => {
    props.setMenuOpen(false)
  }

  const isCurrentPath = ({ url, isCurrentPath }) => {
    const { currentPath } = props

    if (isCurrentPath) return isCurrentPath(currentPath)

    return !!currentPath.match(new RegExp(`^${url}(?!/)`))
  }

  const checkActiveStatus = item => {
    if (item.children) {
      return !isEmpty(compact(item.children.filter(checkActiveStatus)))
    }

    return isCurrentPath(item)
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const renderChildren = (item, key) => {
    // NavLink expects a string value or undefined for the active prop
    const active = checkActiveStatus(item) ? item.url : undefined

    if (item.enabled === false) return null

    const { collapsed, enableWrapping } = props
    const { isNew } = item

    return (
      <CustomSubLink
        exact={!item.isCurrentPath}
        to={item.url}
        data-id={item.dataId}
        key={`sub-menu-${key}-${item.name}`}
        onClick={handleChildrenClick}
        active={active}>
        <ItemText collapsed={collapsed} enableWrapping={enableWrapping}>
          {item.name}
          {isNew && <NewBadge>{isNew}</NewBadge>}
        </ItemText>
      </CustomSubLink>
    )
  }

  const renderParentItem = ({ name, icon, collapsed, isExpanded, isNew }) => {
    const { collapsed: sidebarCollapsed } = props

    return (
      <>
        {icon}
        <>
          <ItemText isParent collapsed={sidebarCollapsed}>
            {name}
            {isNew && <NewBadge>{isNew}</NewBadge>}
          </ItemText>
          {!collapsed && (
            <CollapseIcon
              height="10px"
              width="12px"
              color={COLORS.grey400}
              direction={isExpanded ? 'up' : 'down'}
              transition={`transform 0.3s ${TRANSITION_TIMING}`}
            />
          )}
        </>
      </>
    )
  }

  const {
    item,
    collapsed,
    ignoreToggleState,
    expandedOption,
    admin,
    flagData,
    appType,
    membershipInfo,
    permissions
  } = props
  const {
    dataId,
    icon,
    name,
    isNew,
    url,
    separator,
    children,
    target,
    isExternal,
    onClick
  } = item

  const isActive = checkActiveStatus(item)
  const checkExpanded = () => {
    return expandedOption === name
  }
  const isExpanded = checkExpanded()
  const childrenContainerRef = useRef()

  const handleOnClick = () => {
    if (onClick) {
      onClick()
    }
  }
  if (
    !showSidebarItem({
      item,
      admin,
      appType,
      flagData,
      membershipInfo,
      permissions
    })
  )
    return null

  if (isExternal) {
    return (
      <>
        {separator && <Separator collapsed={collapsed} />}
        <ItemWrapper
          isActive={isActive}
          separator={separator}
          collapsed={collapsed}
          onClick={handleOnClick}>
          <CustomAnchor
            href={url}
            target={target}
            data-id={dataId}
            item={{ ...item, isActive, collapsed, ignoreToggleState }}>
            {icon}
            <ItemText collapsed={collapsed}>{name}</ItemText>
          </CustomAnchor>
        </ItemWrapper>
      </>
    )
  }

  // we do this validation check here instead of in the render method otherwise we can end up with a
  // parent with no children which function as a menu link which expands but should function as a link
  // e.g. the Launch menu item which should show the upgrade page if there are no children
  const filteredChildren = children
    ? children.filter(child =>
        showSidebarItem({
          item: child,
          admin,
          appType,
          flagData,
          membershipInfo,
          permissions
        })
      )
    : []

  if (filteredChildren.length > 0) {
    const updatedChildren = filteredChildren.map(child => {
      return {
        ...child,
        collapsed
      }
    })

    return (
      <>
        {separator && <Separator collapsed={collapsed} />}
        <ItemWrapper
          isActive={isActive}
          separator={separator}
          collapsed={collapsed}
          isExpanded={isExpanded}
          expandedHeight={44 + childrenContainerRef?.current?.clientHeight || 0}
          isParent>
          <CustomNav
            active={isActive}
            data-id={dataId}
            onClick={() => handleParentClick(name, isExpanded)}
            item={{
              ...item,
              isActive,
              collapsed,
              ignoreToggleState,
              isExpanded
            }}>
            {renderParentItem({
              name,
              icon,
              collapsed,
              isExpanded,
              isNew
            })}
          </CustomNav>
          <ChildrenContainer
            ref={childrenContainerRef}
            collapsed={collapsed}
            isExpanded={isExpanded}
            onClick={scrollToTop}>
            {updatedChildren.map((childItem, key) =>
              renderChildren(childItem, key, handleChildrenClick)
            )}
          </ChildrenContainer>
        </ItemWrapper>
      </>
    )
  }

  return (
    <>
      {separator && <Separator collapsed={collapsed} />}
      <ItemWrapper
        isActive={isActive}
        separator={separator}
        collapsed={collapsed}
        onClick={scrollToTop}>
        <CustomNavLink
          exact={!item.isCurrentPath}
          to={url}
          data-id={dataId}
          target={target}
          item={{
            ...item,
            isActive,
            collapsed,
            ignoreToggleState
          }}>
          {icon}
          <ItemText collapsed={collapsed}>
            {name} {isNew && <NewBadge>{isNew}</NewBadge>}
          </ItemText>
        </CustomNavLink>
      </ItemWrapper>
    </>
  )
}

MobileMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  collapsed: PropTypes.bool,
  ignoreToggleState: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  enableWrapping: PropTypes.bool,
  expandedOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),
  admin: PropTypes.bool,
  flagData: flagDataTypes,
  appType: PropTypes.string,
  membershipInfo: PropTypes.object,
  expandSidebarOption: PropTypes.func,
  currentPath: PropTypes.string.isRequired,
  permissions: PropTypes.object,
  setMenuOpen: PropTypes.func.isRequired,
  isNew: PropTypes.string
}

MobileMenuItem.defaultProps = {
  flagData: {},
  isNew: undefined,
  collapsed: false,
  ignoreToggleState: false,
  enableWrapping: false,
  admin: false,
  expandedOption: null,
  membershipInfo: undefined,
  expandSidebarOption: undefined,
  appType: undefined,
  permissions: {}
}
